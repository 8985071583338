
.swiper-button-prev {
  left: -30px; /* Position 30px outside the content */
}

.swiper-button-next {
  right: -30px; /* Position 30px outside the content */
}

/* Change button hover effects */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #d43b2e; /* Hover background color */
}
