@import 'datatables.net-dt';
@import 'datatables.net-responsive-dt';
@import 'datatables.net-select-dt';
/* General DataTable Styles */
.dataTables_wrapper {
    padding: 20px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    overflow: hidden;
  }
  
  /* Table Styling */
  .dataTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px; /* Add space between rows */
  }
  
  .dataTable th,
  .dataTable td {
    padding: 15px; /* More padding for a spacious look */
    text-align: left;
    vertical-align: middle;
    font-size: 15px;
    color: #333; /* Dark text for contrast */
  }
  
  .dataTable th {
    background-color: #01132b; /* Blue background for headers */
    color: #fff; /* White text for headers */
    font-weight: bold;
    border: none;
  }
  
  .dataTable tbody tr {
    background-color: #fff; /* White background for rows */
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect on table rows */
  .dataTable tbody tr:hover {
    background-color: #f1f7ff; /* Light blue on hover */
  }
  
  /* Zebra striping effect */
  .dataTable tbody tr:nth-child(even) {
    background-color: #f7f7f7; /* Light gray for alternating rows */
  }
  
  /* Responsive styling for smaller screens */
  @media screen and (max-width: 768px) {
    .dataTables_wrapper {
      padding: 10px;
    }
  
    .dataTable th,
    .dataTable td {
      padding: 10px;
      font-size: 14px;
    }
  
    /* Make sure the table looks good on small screens */
    .dataTable {
      font-size: 12px;
      border-spacing: 0 5px;
    }
  }
  
  /* Pagination Styling */
  .dataTables_paginate {
    margin-top: 20px;
  }
  
  .dataTables_paginate .paginate_button {
    padding: 8px 12px;
    margin: 0 5px;
    background-color: #01132b;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .dataTables_paginate .paginate_button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .dataTables_paginate .paginate_button.current {
    background-color: #0056b3;
  }
  
  /* Search Bar Styling */
  .dataTables_filter input {
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #01132b;
    width: 250px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .dataTables_filter input:focus {
    border-color: #0056b3;
  }
  
  /* Select Rows Styling */
  .dataTable tbody tr.selected {
    background-color: #cce5ff !important;
    color: #004085;
  }
  
  /* Length Menu Dropdown */
  .dataTables_length select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #01132b;
    outline: none;
    cursor: pointer;
  }
  
  /* Info Section (bottom left) */
  .dataTables_info {
    margin-top: 10px;
    color: #333;
  }
  
  /* Loading Indicator */
  .dataTables_processing {
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    color: #333;
  }
  